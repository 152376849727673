{
  "tooltips": {
    "placeMarker": "Κάντε κλικ για να τοποθετήσετε Δείκτη",
    "firstVertex": "Κάντε κλικ για να τοποθετήσετε το πρώτο σημείο",
    "continueLine": "Κάντε κλικ για να συνεχίσετε να σχεδιάζετε",
    "finishLine": "Κάντε κλικ σε οποιονδήποτε υπάρχον σημείο για να ολοκληρωθεί",
    "finishPoly": "Κάντε κλικ στο πρώτο σημείο για να τελειώσετε",
    "finishRect": "Κάντε κλικ για να τελειώσετε",
    "startCircle": "Κάντε κλικ για να τοποθετήσετε κέντρο Κύκλου",
    "finishCircle": "Κάντε κλικ για να ολοκληρώσετε τον Κύκλο",
    "placeCircleMarker": "Κάντε κλικ για να τοποθετήσετε Κυκλικό Δείκτη"
  },
  "actions": {
    "finish": "Τέλος",
    "cancel": "Ακύρωση",
    "removeLastVertex": "Κατάργηση τελευταίου σημείου"
  },
  "buttonTitles": {
    "drawMarkerButton": "Σχεδίαση Δείκτη",
    "drawPolyButton": "Σχεδίαση Πολυγώνου",
    "drawLineButton": "Σχεδίαση Γραμμής",
    "drawCircleButton": "Σχεδίαση Κύκλου",
    "drawRectButton": "Σχεδίαση Ορθογωνίου",
    "editButton": "Επεξεργασία Επιπέδων",
    "dragButton": "Μεταφορά Επιπέδων",
    "cutButton": "Αποκοπή Επιπέδων",
    "deleteButton": "Κατάργηση Επιπέδων",
    "drawCircleMarkerButton": "Σχεδίαση Κυκλικού Δείκτη",
    "snappingButton": "Προσκόλληση του Δείκτη μεταφοράς σε άλλα Επίπεδα και Κορυφές",
    "pinningButton": "Περικοπή κοινών κορυφών μαζί",
    "rotateButton": "Περιστρέψτε το στρώμα"
  }
}
