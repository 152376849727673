{
  "tooltips": {
    "placeMarker": "Adaugă un punct",
    "firstVertex": "Apasă aici pentru a adăuga primul Vertex",
    "continueLine": "Apasă aici pentru a continua desenul",
    "finishLine": "Apasă pe orice obiect pentru a finisa desenul",
    "finishPoly": "Apasă pe primul obiect pentru a finisa",
    "finishRect": "Apasă pentru a finisa",
    "startCircle": "Apasă pentru a desena un cerc",
    "finishCircle": "Apasă pentru a finisa un cerc",
    "placeCircleMarker": "Adaugă un punct"
  },
  "actions": {
    "finish": "Termină",
    "cancel": "Anulează",
    "removeLastVertex": "Șterge ultimul Vertex"
  },
  "buttonTitles": {
    "drawMarkerButton": "Adaugă o bulină",
    "drawPolyButton": "Desenează un poligon",
    "drawLineButton": "Desenează o linie",
    "drawCircleButton": "Desenează un cerc",
    "drawRectButton": "Desenează un dreptunghi",
    "editButton": "Editează straturile",
    "dragButton": "Mută straturile",
    "cutButton": "Taie straturile",
    "deleteButton": "Șterge straturile",
    "drawCircleMarkerButton": "Desenează marcatorul cercului",
    "snappingButton": "Fixați marcatorul glisat pe alte straturi și vârfuri",
    "pinningButton": "Fixați vârfurile partajate împreună",
    "rotateButton": "Rotiți stratul"
  }
}
