{
  "tooltips": {
    "placeMarker": "Klikk for å plassere punkt",
    "firstVertex": "Klikk for å plassere første punkt",
    "continueLine": "Klikk for å tegne videre",
    "finishLine": "Klikk på et eksisterende punkt for å fullføre",
    "finishPoly": "Klikk første punkt for å fullføre",
    "finishRect": "Klikk for å fullføre",
    "startCircle": "Klikk for å sette sirkel midtpunkt",
    "finishCircle": "Klikk for å fullføre sirkel",
    "placeCircleMarker": "Klikk for å plassere sirkel",
    "placeText": "Klikk for å plassere tekst"
  },
  "actions": {
    "finish": "Fullfør",
    "cancel": "Kanseller",
    "removeLastVertex": "Fjern forrige punkt"
  },
  "buttonTitles": {
    "drawMarkerButton": "Tegn punkt",
    "drawPolyButton": "Tegn flate",
    "drawLineButton": "Tegn linje",
    "drawCircleButton": "Tegn sirkel",
    "drawRectButton": "Tegn rektangel",
    "editButton": "Rediger objekter",
    "dragButton": "Dra objekter",
    "cutButton": "Kutt objekter",
    "deleteButton": "Fjern objekter",
    "drawCircleMarkerButton": "Tegn sirkel-punkt",
    "snappingButton": "Fest dratt punkt til andre objekter og punkt",
    "pinningButton": "Pin delte punkter sammen",
    "rotateButton": "Rotér objekter",
    "drawTextButton": "Tegn tekst",
    "scaleButton": "Skalér objekter",
    "autoTracingButton": "Automatisk sporing av linje"
  },
  "measurements": {
    "totalLength": "Lengde",
    "segmentLength": "Segmentlengde",
    "area": "Område",
    "radius": "Radius",
    "perimeter": "Omriss",
    "height": "Høyde",
    "width": "Bredde",
    "coordinates": "Posisjon",
    "coordinatesMarker": "Posisjonsmarkør"
  }
}
