{
  "tooltips": {
    "placeMarker": "单击放置标记",
    "firstVertex": "单击放置首个顶点",
    "continueLine": "单击继续绘制",
    "finishLine": "单击任何存在的标记以完成",
    "finishPoly": "单击第一个标记以完成",
    "finishRect": "单击完成",
    "startCircle": "单击放置圆心",
    "finishCircle": "单击完成圆形",
    "placeCircleMarker": "点击放置圆形标记"
  },
  "actions": {
    "finish": "完成",
    "cancel": "取消",
    "removeLastVertex": "移除最后的顶点"
  },
  "buttonTitles": {
    "drawMarkerButton": "绘制标记",
    "drawPolyButton": "绘制多边形",
    "drawLineButton": "绘制线段",
    "drawCircleButton": "绘制圆形",
    "drawRectButton": "绘制长方形",
    "editButton": "编辑图层",
    "dragButton": "拖拽图层",
    "cutButton": "剪切图层",
    "deleteButton": "删除图层",
    "drawCircleMarkerButton": "画圆圈标记",
    "snappingButton": "将拖动的标记捕捉到其他图层和顶点",
    "pinningButton": "将共享顶点固定在一起",
    "rotateButton": "旋转图层"
  }
}
