{
  "tooltips": {
    "placeMarker": "Click to place marker",
    "firstVertex": "Click to place first vertex",
    "continueLine": "Click to continue drawing",
    "finishLine": "Click any existing marker to finish",
    "finishPoly": "Click first marker to finish",
    "finishRect": "Click to finish",
    "startCircle": "Click to place circle center",
    "finishCircle": "Click to finish circle",
    "placeCircleMarker": "Click to place circle marker",
    "placeText": "Click to place text",
    "selectFirstLayerFor": "Select first layer for {action}",
    "selectSecondLayerFor": "Select second layer for {action}"
  },
  "actions": {
    "finish": "Finish",
    "cancel": "Cancel",
    "removeLastVertex": "Remove Last Vertex"
  },
  "buttonTitles": {
    "drawMarkerButton": "Draw Marker",
    "drawPolyButton": "Draw Polygons",
    "drawLineButton": "Draw Polyline",
    "drawCircleButton": "Draw Circle",
    "drawRectButton": "Draw Rectangle",
    "editButton": "Edit Layers",
    "dragButton": "Drag Layers",
    "cutButton": "Cut Layers",
    "deleteButton": "Remove Layers",
    "drawCircleMarkerButton": "Draw Circle Marker",
    "snappingButton": "Snap dragged marker to other layers and vertices",
    "pinningButton": "Pin shared vertices together",
    "rotateButton": "Rotate Layers",
    "drawTextButton": "Draw Text",
    "scaleButton": "Scale Layers",
    "autoTracingButton": "Auto trace Line",
    "snapGuidesButton": "Show SnapGuides",
    "unionButton": "Union layers",
    "differenceButton": "Subtract layers"
  },
  "measurements": {
    "totalLength": "Length",
    "segmentLength": "Segment length",
    "area": "Area",
    "radius": "Radius",
    "perimeter": "Perimeter",
    "height": "Height",
    "width": "Width",
    "coordinates": "Position",
    "coordinatesMarker": "Position Marker"
  }
}
