{
  "tooltips": {
    "placeMarker": "Platziere den Marker mit Klick",
    "firstVertex": "Platziere den ersten Marker mit Klick",
    "continueLine": "Klicke, um weiter zu zeichnen",
    "finishLine": "Beende mit Klick auf existierenden Marker",
    "finishPoly": "Beende mit Klick auf ersten Marker",
    "finishRect": "Beende mit Klick",
    "startCircle": "Platziere das Kreiszentrum mit Klick",
    "finishCircle": "Beende den Kreis mit Klick",
    "placeCircleMarker": "Platziere den Kreismarker mit Klick",
    "placeText": "Platziere den Text mit Klick"
  },
  "actions": {
    "finish": "Beenden",
    "cancel": "Abbrechen",
    "removeLastVertex": "Letzten Vertex löschen"
  },
  "buttonTitles": {
    "drawMarkerButton": "Marker zeichnen",
    "drawPolyButton": "Polygon zeichnen",
    "drawLineButton": "Polyline zeichnen",
    "drawCircleButton": "Kreis zeichnen",
    "drawRectButton": "Rechteck zeichnen",
    "editButton": "Layer editieren",
    "dragButton": "Layer bewegen",
    "cutButton": "Layer schneiden",
    "deleteButton": "Layer löschen",
    "drawCircleMarkerButton": "Kreismarker zeichnen",
    "snappingButton": "Bewegter Layer an andere Layer oder Vertexe einhacken",
    "pinningButton": "Vertexe an der gleichen Position verknüpfen",
    "rotateButton": "Layer drehen",
    "drawTextButton": "Text zeichnen",
    "scaleButton": "Layer skalieren",
    "autoTracingButton": "Linie automatisch nachzeichen"
  },
  "measurements": {
    "totalLength": "Länge",
    "segmentLength": "Segment Länge",
    "area": "Fläche",
    "radius": "Radius",
    "perimeter": "Umfang",
    "height": "Höhe",
    "width": "Breite",
    "coordinates": "Position",
    "coordinatesMarker": "Position Marker"
  }
}
