{
  "tooltips": {
    "placeMarker": "Clicca per posizionare un Marker",
    "firstVertex": "Clicca per posizionare il primo vertice",
    "continueLine": "Clicca per continuare a disegnare",
    "finishLine": "Clicca qualsiasi marker esistente per terminare",
    "finishPoly": "Clicca il primo marker per terminare",
    "finishRect": "Clicca per terminare",
    "startCircle": "Clicca per posizionare il punto centrale del cerchio",
    "finishCircle": "Clicca per terminare il cerchio",
    "placeCircleMarker": "Clicca per posizionare un Marker del cherchio"
  },
  "actions": {
    "finish": "Termina",
    "cancel": "Annulla",
    "removeLastVertex": "Rimuovi l'ultimo vertice"
  },
  "buttonTitles": {
    "drawMarkerButton": "Disegna Marker",
    "drawPolyButton": "Disegna Poligoni",
    "drawLineButton": "Disegna Polilinea",
    "drawCircleButton": "Disegna Cerchio",
    "drawRectButton": "Disegna Rettangolo",
    "editButton": "Modifica Livelli",
    "dragButton": "Sposta Livelli",
    "cutButton": "Ritaglia Livelli",
    "deleteButton": "Elimina Livelli",
    "drawCircleMarkerButton": "Disegna Marker del Cerchio",
    "snappingButton": "Snap ha trascinato il pennarello su altri strati e vertici",
    "pinningButton": "Pin condiviso vertici insieme",
    "rotateButton": "Ruota livello"
  }
}
